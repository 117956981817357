import axios from "axios";
import { getUserFromLocalStorage } from "./localstorage";
import { logoutUser } from "../features/user/userSlice";

const customFetch = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// console.log(process.env)
customFetch.interceptors.request.use((config) => {
  const user = getUserFromLocalStorage();
  if (user?.data?.token) {
    config.headers["Authorization"] = `Bearer ${user.data.token}`;
  }
  return config;
});

// customFetch.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response && error.response.status === 400) {
//       if (
//         error.response &&
//         error.response.status === 400 &&
//         error.response.data.message === "Expired Access Credentials"
//       ) {
//         // console.log(error.response);
//         localStorage.removeItem("userProvider");
//         window.location.href = "/auth/login";
//       }
//     }
//     /** Handle errors **/
//     return Promise.reject(error);
//   }
// );

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
  if (error.response.status === 401) {
    thunkAPI.dispatch(logoutUser());

    window.location.href = "/auth/login";
  }
  return thunkAPI.rejectWithValue(error.response.data.message);
};

export default customFetch;
