import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { Input } from "../forms";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getProviderCreditTransactions,
  submitPassCode,
} from "../../features/user/userSlice";

const PassModal = ({
  openFlancePassModal,
  setOpenFlancePassModal,
  containerClassName,
  storedData,
}) => {
  const { passDetails, submitClientsManuallyLoading } = useSelector(
    (store) => store.user
  );

  // const getBranch = localStorage.getItem("branch");

  // console.log(typeof getBranch);

  // const storedData =
  // getBranch && getBranch === String ? JSON.parse(getBranch) : null;

  const [passInfo, setPassInfo] = useState("");
  const dispatch = useDispatch();
  const submitCode = () => {
    dispatch(
      submitPassCode({
        accessCode: passInfo,
        visitTimestamp: new Date(),
        providerBranchId: storedData?.id,
      })
    ).then((res) => {
      if (res?.type === "user/submitPassCode/fulfilled") {
        setOpenFlancePassModal(false);
        dispatch(getProviderCreditTransactions());
      }
    });
  };
  return (
    <Modal
      handleClose={() => {
        setOpenFlancePassModal(false);
      }}
      title={"Enter passcode"}
      show={openFlancePassModal}
      //   containerClassName={`success-modal ${containerClassName}`}
      isModalBig={false}
      containerClassName="addClients--modal"
    >
      <p className="tx-c">Please enter your passcode.</p>
      <Input
        className={"mt-24"}
        isFloatingLabel={false}
        value={passInfo}
        onChange={(e) => setPassInfo(e.target.value)}
      />
      <Button
        variant="primary"
        type="submit"
        className="mt-40"
        block
        // disabled={isLoading}
        onClick={() => submitCode()}
      >
        {submitClientsManuallyLoading ? "Verifying..." : "Verify code"}
      </Button>
    </Modal>
  );
};

export default PassModal;
