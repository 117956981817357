import React from "react";
import Button from "../../components/Button";

import { useSelector } from "react-redux";
import { Input } from "../../components/forms";
// import { getProviderImage } from "../../features/user/userSlice";

const Account = () => {
  const { user } = useSelector((store) => store.user);
  // const dispatch = useDispatch();

  console.log(user);

  // useEffect(() => {
  //   dispatch(getProviderImage(user.id));
  // }, []);

  console.log(user?.data);

  return (
    <div className="account">
      <div className="account__header">
        <div className="account__header--img">
          <div className="img__container">
            <img src="" alt="" />
          </div>
          <div className="img__text">
            <h4>{user?.data?.providerName}</h4>
            <p>{user?.data?.email}</p>
          </div>
        </div>
        <div className="account__header--btn">
          <Button
            variant="primary"
            type="submit"
            className="change"
            block
            size={"sm"}
          >
            Change Logo
          </Button>
          <p>Recommended size is 100x100px</p>
        </div>
      </div>

      <div className="account__body">
        <h3>Business information</h3>
        <div className="account__body--deets">
          <div className="inputgroup mt-24">
            <Input
              type="text"
              id="businessName"
              label="Business Name"
              name="businessName"
              disabled
              value={user?.data?.providerName}
              placeholder="Business Name"
            />
            <Input
              type="text"
              id="email"
              label="Email address"
              name="email"
              disabled
              value={user?.data?.email}
              //   onChange={handleChange}
              placeholder="Email address"
            />
          </div>
          <div className="inputgroup">
            <Input
              type="text"
              id="serviceCategory"
              label="Service Category"
              name="serviceCategory"
              disabled
              value={"Gym"}
              //   onChange={handleChange}
              placeholder="Service Category"
            />
            <Input
              type="text"
              id="phone"
              label="Phone Number"
              name="phone"
              disabled
              value={user?.data?.phone}
              //   onChange={handleChange}
              placeholder="Phone Number"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
