// import { createSlice, createAsyncThunk } from "reduxjs/toolkit";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import toast from "react-hot-toast";
import {
  addItemToLocalStorage,
  addStepsToLocalStorage,
  addUserToLocalStorage,
  getStepsFromLocalStorage,
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from "../../utils/localstorage";
import nprogress from "nprogress";

const initialState = {
  isLoading: false,
  user: getUserFromLocalStorage(),
  subscriberList: [],
  errMsg: null,
  changePassword: false,
  passwordChanged: false,
  providerCredit: null,
  transactions: [],
  userSignUp: null,
  allState: [],
  stateLga: [],
  userImgLink: null,
  subnitBasicInfo: "",
  steps: getStepsFromLocalStorage() || 0,
  navTitle: "",
  providerBenefit: [],
  benefitCategory: [],
  uploadingCsv: false,
  csvSucces: false,
  submitClientsManuallyLoading: false,
  submitClientsManuallySuccess: false,
  client: {},
  passDetails: {},
  providerBranch: [],
  providerBranches: [],
  providerImage: null,
};

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (user, thunkAPI) => {
    localStorage.setItem("businessMail", JSON.stringify(user.businessEmail));
    try {
      const resp = await customFetch.post("auth/signup/provider", user);
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    try {
      const resp = await customFetch.post("auth/signin/provider", user);
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const getProviderSubscribers = createAsyncThunk(
  "user/getProviderSubscribers",
  async (_, thunkAPI) => {
    try {
      const resp = await customFetch.get("provider/clients");
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getProviderBranch = createAsyncThunk(
  "user/getProviderBranch",
  async (_, thunkAPI) => {
    try {
      const resp = await customFetch.get("provider/branches");
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getProviderBranchByCategory = createAsyncThunk(
  "user/getProviderBranchByCategory",
  async (_, thunkAPI) => {
    try {
      const resp = await customFetch.get("branches/category/GYM");
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getProviderSubscribersById = createAsyncThunk(
  "user/getProviderSubscribersById",
  async (payload, thunkAPI) => {
    try {
      const resp = await customFetch.get(
        `provider/clients/${payload?.id}/${payload?.clientType}`
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

export const getProviderCredit = createAsyncThunk(
  "user/creditProvider",
  async (id, thunkAPI) => {
    try {
      const resp = await customFetch.get(`provider/credit`);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getProviderCreditTransactions = createAsyncThunk(
  "user/creditProviderTransacions",
  async (id, thunkAPI) => {
    try {
      const resp = await customFetch.get(`provider/credit/transactions`);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

export const fetchAllStates = createAsyncThunk(
  "user/allStates",
  async (_, thunkAPI) => {
    try {
      const response = await customFetch.get("benefit/states");
      const findState = response?.data;
      return findState;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const fetchImageLink = createAsyncThunk(
  "user/fetchImageLink",
  async (_, thunkAPI) => {
    try {
      const response = await customFetch.get(`provider/signed-url/png`);
      return response?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const fetchProviderBenefit = createAsyncThunk(
  "user/fetchProviderBenefit",
  async (_, thunkAPI) => {
    try {
      const response = await customFetch.get(`provider/benefit`);
      return response?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const fetchAllLgas = createAsyncThunk(
  "user/allLgas",
  async (state, thunkAPI) => {
    try {
      const response = await customFetch.get(`benefit/states/${state}/lgas`);
      return response?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const fetchProviderCategory = createAsyncThunk(
  "user/fetchProviderCategory",
  async (_, thunkAPI) => {
    try {
      const response = await customFetch.get(`auth/provider/categories`);
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);
export const resetProviderPassword = createAsyncThunk(
  "user/resetProviderPassword",
  async (user, thunkAPI) => {
    try {
      const resp = await customFetch.post(
        "auth/provider/password/change",
        user
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const submitProviderInfo = createAsyncThunk(
  "user/submitProviderInfo",
  async (info, thunkAPI) => {
    try {
      const resp = await customFetch.post("provider/info", info);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const submitPassCode = createAsyncThunk(
  "user/submitPassCode",
  async (info, thunkAPI) => {
    try {
      const resp = await customFetch.post(
        "provider/flance-pass/visit/log",
        info
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const submitPlanDetails = createAsyncThunk(
  "user/submitPlanDetails",
  async (info, thunkAPI) => {
    try {
      const resp = await customFetch.post("provider/benefit", info);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const submitClientManually = createAsyncThunk(
  "user/submitClientManually",
  async (info, thunkAPI) => {
    try {
      const resp = await customFetch.post("provider/clients", info);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const updateProviderClients = createAsyncThunk(
  "user/updateProviderClients",
  async (info, thunkAPI) => {
    try {
      const resp = await customFetch.put("provider/clients", info);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const addContactPerson = createAsyncThunk(
  "user/addContactPerson",
  async (info, thunkAPI) => {
    try {
      const resp = await customFetch.post("provider/contactPerson", info);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getContactPerson = createAsyncThunk(
  "user/getContactPerson",
  async (info, thunkAPI) => {
    try {
      const resp = await customFetch.get("provider/contactPerson");
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const updateProviderBenefits = createAsyncThunk(
  "user/updateProviderBenefits",
  async (info, thunkAPI) => {
    try {
      const resp = await customFetch.put(`provider/benefit/${info?.id}`, {
        priceOptions: info?.price,
      });
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const submitProviderClientsWithCSV = createAsyncThunk(
  "user/submitProviderClientsWithCSV",
  async (file, thunkAPI) => {
    try {
      const resp = await customFetch.post("provider/clients/upload", file);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getProviderImage = createAsyncThunk(
  "user/getProviderImage",
  async (providerId, thunkAPI) => {
    try {
      const resp = await customFetch.post(`provider/info/${providerId}`);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.user = null;
      removeUserFromLocalStorage();
      localStorage.removeItem("currentStep");
      window.location.href = "/auth/login";
    },
    returnChangePassword: (state) => {
      state.changePassword = false;
    },
    increaseSteps: (state) => {
      state.steps = 0;
      addStepsToLocalStorage(state.steps);
    },
    changeNavtitle: (state, { payload }) => {
      state.navTitle = payload;
    },
    resetManaulClientUpload: (state, { payload }) => {
      state.submitClientsManuallySuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.userSignUp = payload;
        toast.success("You are successfully signned up");
        state.user = payload;
        addUserToLocalStorage(payload);
        localStorage.removeItem("loginData");
        localStorage.removeItem("currentStep");
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errMsg = payload;
        toast.error(payload?.message);
        state.changePassword = false;
        localStorage.removeItem("loginData");
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success("You are successfully logged in");
        state.user = payload;
        addUserToLocalStorage(payload);
        localStorage.removeItem("loginData");
        state.changePassword = false;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (payload?.message === "Kindly Proceed To Change Your Password") {
          state.changePassword = true;
          toast.loading(payload?.message);
          addItemToLocalStorage("loginData", { id: payload?.data });
        } else {
          state.changePassword = false;
          localStorage.removeItem("loginData");
          toast.error(payload?.message);
        }
        state.errMsg = payload;
      })
      .addCase(getProviderSubscribers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderSubscribers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.subscriberList = payload;
      })
      .addCase(getProviderSubscribers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errMsg = payload;
      })
      .addCase(getProviderBranch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderBranch.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.providerBranch = payload;
      })
      .addCase(getProviderBranch.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errMsg = payload;
      })
      .addCase(getProviderBranchByCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderBranchByCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.providerBranches = payload;
      })
      .addCase(getProviderBranchByCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errMsg = payload;
      })
      .addCase(getProviderSubscribersById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderSubscribersById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.client = payload;
      })
      .addCase(getProviderSubscribersById.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.message);
      })
      .addCase(fetchProviderCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProviderCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.benefitCategory = payload;
      })
      .addCase(fetchProviderCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errMsg = payload;
      })
      .addCase(fetchImageLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchImageLink.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.userImgLink = payload;
      })
      .addCase(fetchImageLink.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errMsg = payload;
      })
      .addCase(fetchAllStates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllStates.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.allState = payload;
      })
      .addCase(fetchAllStates.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errMsg = payload;
      })
      .addCase(fetchAllLgas.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllLgas.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.stateLga = payload;
      })
      .addCase(fetchAllLgas.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errMsg = payload;
      })
      .addCase(resetProviderPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetProviderPassword.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.passwordChanged = true;
        localStorage.removeItem("loginData");
        toast.success("Password changed successfully");
      })
      .addCase(resetProviderPassword.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.message);
      })
      .addCase(getProviderCredit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderCredit.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.providerCredit = payload;
      })
      .addCase(getProviderCredit.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.message);
      })
      .addCase(submitProviderInfo.pending, (state) => {
        state.isLoading = true;
        nprogress.start();
      })
      .addCase(submitProviderInfo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload?.message);
        state.steps = 1;
        addStepsToLocalStorage(state.steps);
        nprogress.done();
      })
      .addCase(submitProviderInfo.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
        nprogress.done();
      })
      .addCase(submitPlanDetails.pending, (state) => {
        state.isLoading = true;
        nprogress.start();
      })
      .addCase(submitPlanDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload?.message);
        state.steps = 2;
        addStepsToLocalStorage(state.steps);
        nprogress.done();
      })
      .addCase(submitPlanDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
        nprogress.done();
      })
      .addCase(submitClientManually.pending, (state) => {
        state.submitClientsManuallyLoading = true;
        state.submitClientsManuallySuccess = false;
        nprogress.start();
      })
      .addCase(submitClientManually.fulfilled, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        state.submitClientsManuallySuccess = true;
        toast.success(payload?.message);
        nprogress.done();
      })
      .addCase(submitClientManually.rejected, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        state.submitClientsManuallySuccess = false;
        toast.error(payload);
        nprogress.done();
      })
      .addCase(updateProviderClients.pending, (state) => {
        state.submitClientsManuallyLoading = true;
        nprogress.start();
      })
      .addCase(updateProviderClients.fulfilled, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        toast.success(payload?.message);
        nprogress.done();
      })
      .addCase(updateProviderClients.rejected, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        toast.error(payload?.message);
        nprogress.done();
      })
      .addCase(addContactPerson.pending, (state) => {
        state.submitClientsManuallyLoading = true;
        nprogress.start();
      })
      .addCase(addContactPerson.fulfilled, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        toast.success(payload?.message);
        nprogress.done();
      })
      .addCase(addContactPerson.rejected, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        toast.error(payload);
        nprogress.done();
      })
      .addCase(getContactPerson.pending, (state) => {
        state.submitClientsManuallyLoading = true;
      })
      .addCase(getContactPerson.fulfilled, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        state.contactPerson = payload;
      })
      .addCase(getContactPerson.rejected, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        toast.error(payload?.message);
      })
      .addCase(submitPassCode.pending, (state) => {
        state.submitClientsManuallyLoading = true;
        nprogress.start();
      })
      .addCase(submitPassCode.fulfilled, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        state.passDetails = payload;
        toast.success(payload?.message);
        nprogress.done();
      })
      .addCase(submitPassCode.rejected, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        toast.error(payload);
        nprogress.done();
      })
      .addCase(updateProviderBenefits.pending, (state) => {
        state.submitClientsManuallyLoading = true;
        nprogress.start();
      })
      .addCase(updateProviderBenefits.fulfilled, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        toast.success(payload?.message);
        nprogress.done();
      })
      .addCase(updateProviderBenefits.rejected, (state, { payload }) => {
        state.submitClientsManuallyLoading = false;
        toast.error(payload?.message);
        nprogress.done();
      })
      .addCase(fetchProviderBenefit.pending, (state) => {
        state.isLoading = true;
        nprogress.start();
      })
      .addCase(fetchProviderBenefit.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.providerBenefit = payload?.data;
        nprogress.done();
      })
      .addCase(fetchProviderBenefit.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
        nprogress.done();
      })
      .addCase(submitProviderClientsWithCSV.pending, (state) => {
        nprogress.start();
        state.uploadingCsv = true;
        state.csvSucces = false;
      })
      .addCase(submitProviderClientsWithCSV.fulfilled, (state, { payload }) => {
        nprogress.done();
        state.uploadingCsv = false;
        state.csvSucces = true;
        toast.success(payload?.message);
      })
      .addCase(submitProviderClientsWithCSV.rejected, (state, { payload }) => {
        nprogress.done();
        state.uploadingCsv = false;
        state.csvSucces = false;
        toast.error("Wrong Csv Format download template for format");
      })
      .addCase(getProviderCreditTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getProviderCreditTransactions.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          state.transactions = payload;
        }
      )
      .addCase(getProviderCreditTransactions.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.message);
      })
      .addCase(getProviderImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderImage.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.providerImage = payload;
      })
      .addCase(getProviderImage.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.message);
      });
  },
});

export const {
  logoutUser,
  returnChangePassword,
  increaseSteps,
  changeNavtitle,
  resetManaulClientUpload,
} = userSlice.actions;

export default userSlice.reducer;
