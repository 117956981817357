import React, { useEffect, useState } from "react";
import { Input } from "../forms";
import Icon from "../Icon";
import BigModal from "./BigModal";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProviderBenefit,
  getProviderBranch,
  submitPlanDetails,
} from "../../features/user/userSlice";

const NewPlanModal = ({ createPlan, setCreatePLan }) => {
  const { isLoading, providerCredit, providerBranch } = useSelector(
    (store) => store.user
  );

  const [plans, setPlans] = useState({
    benefitName: "",
    priceOptions: [
      { duration: "DAILY", price: "" },
      { duration: "WEEKLY", price: "" },
      { duration: "BI_WEEKLY", price: "" },
      { duration: "MONTHLY", price: "" },
      { duration: "QUARTERLY", price: "" },
      { duration: "ANNUALLY", price: "" },
      { duration: "BIANNUALLY", price: "" },
    ],
    includesPersonalTrainer: false,
    hasGuestPass: false,
    membershipFee: false,
    publishOnFlance: true,
    benefitCategory: "GYM",
  });

  const dispatch = useDispatch();
  const submitChanges = () => {
    dispatch(
      submitPlanDetails({
        benefits: [
          {
            benefitName: plans?.benefitName,
            priceOptions: plans?.priceOptions.filter(
              (option) => option.price !== ""
            ),
            includesPersonalTrainer: plans?.includesPersonalTrainer,
            hasGuestPass: plans?.hasGuestPass,
            membershipFee: plans?.membershipFee,
            publishOnFlance: plans?.publishOnFlance,
            benefitCategory: "GYM",
          },
        ],
        providerBranchIds: providerBranch?.branches.map((ids) => ids.id),
      })
    ).then((res) => {
      if (res?.type === "user/submitPlanDetails/fulfilled") {
        setCreatePLan(false);
        dispatch(fetchProviderBenefit());
      }
    });
  };

  useEffect(() => {
    dispatch(getProviderBranch());
  }, []);

  const handlePriceChange = (optionIndex, value) => {
    const updatedPriceOptions = plans.priceOptions.map((option, index) =>
      index === optionIndex ? { ...option, price: value } : option
    );
    setPlans({ ...plans, priceOptions: updatedPriceOptions });
  };

  // Handle other input changes (benefit name, checkboxes)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlans({ ...plans, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPlans({ ...plans, [name]: checked });
  };

  return (
    <BigModal
      isModalOpen={createPlan}
      isVisible={createPlan}
      handleClose={() => setCreatePLan(false)}
      headerTitle={"Add New Plan"}
    >
      <div className="planPricing">
        <div className="planPicing--header__info">
          <p>Enter Plan name</p>
          <div className="add--input mt-16 ">
            <Input
              id={`benefitName`}
              name={`benefitName`}
              placeHolder="(e.g., Monthly, Couple, Family, Weekend, Day Pass, Weekly)"
              isFloatingLabel={false}
              value={plans?.benefitName}
              onChange={handleInputChange}
            />
          </div>
          <div className="price__duration">
            <p>Select Plan Price and Duration</p>
            <div className="price__duration--lists mt-16 ">
              {plans?.priceOptions?.map((option, optionIndex) => (
                <div className="list" key={option.duration}>
                  <input
                    id={option.duration}
                    type="number"
                    value={option.price}
                    onChange={(e) =>
                      handlePriceChange(optionIndex, e.target.value)
                    }
                    placeHolder="₦ 0000 000"
                    onKeyDown={(e) =>
                      ["e", "E", "-"].includes(e.key) && e.preventDefault()
                    }
                  />
                  <span className={`${option.price ? "active" : ""}`}>
                    {option.duration === "BI_WEEKLY"
                      ? "Bi-weekly"
                      : option.duration.toLowerCase()}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="plan--deets">
            <label>
              <input
                type="checkbox"
                checked={plans.includesPersonalTrainer}
                name="includesPersonalTrainer"
                onChange={handleCheckboxChange}
              />
              Includes personal trainer
            </label>
            <label>
              <input
                type="checkbox"
                checked={plans.hasGuestPass}
                name="hasGuestPass"
                onChange={handleCheckboxChange}
              />
              Allow guest pass
            </label>
          </div>
          <div className="plan--deets">
            <div className="showOnFlance">
              <div className="toggle--container">
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    name={`membershipFee`}
                    id={`membershipFee`}
                    checked={plans.membershipFee}
                    onChange={handleCheckboxChange}
                  />
                  <label className="label" htmlFor={`membershipFee`}>
                    <span className="inner" />
                    <span className="switch" />
                  </label>
                </div>
                Allow Membership Fee
              </div>
            </div>
          </div>
          <div className="plan--deets">
            <div className="showOnFlance">
              <div className="toggle--container">
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    name={`publishOnFlance`}
                    id={`publishOnFlance`}
                    checked={plans.publishOnFlance}
                    onChange={handleCheckboxChange}
                  />
                  <label className="label" htmlFor={`publishOnFlance`}>
                    <span className="inner" />
                    <span className="switch" />
                  </label>
                </div>
                Show plan on Flance
              </div>
            </div>
            {providerCredit?.commission && (
              <div className="commission">
                <Icon id="light-bulb" width="20" height="16" />
                <p>
                  A {providerCredit?.commission}% is retained from the price of
                  each plan sold on Flance.
                </p>
              </div>
            )}
          </div>
        </div>
        <Button
          variant="primary"
          type="submit"
          className="mt-24"
          block
          size={"md"}
          onClick={() => {
            submitChanges();
          }}
        >
          {isLoading ? "Adding..." : "Add Plan"}
        </Button>
      </div>
    </BigModal>
  );
};

export default NewPlanModal;
