import React, { useEffect, useState } from "react";
import Logo from "../assets/svgs/logo.svg";
import Icon from "./Icon";
import { navigations } from "../utils/navigations";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProviderBranch } from "../features/user/userSlice";

const DashboardSIdebar = ({
  // active,
  // setActive,
  // setSlidingSidebar,
  // slidingSidebar,
  splitLocation,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { providerBranch } = useSelector((store) => store.user);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);

  useEffect(() => {
    dispatch(getProviderBranch());
  }, []);

  useEffect(() => {
    const getFromLocalStorage = (key) => {
      try {
        const value = localStorage.getItem(key);
        if (value === null || value === undefined || value === "undefined") {
          return null; // Return null if the key doesn't exist
        }
        setSelectedBranch(JSON.parse(value)); // Parse the JSON value
      } catch (error) {
        console.error("Error parsing JSON from localStorage", error);
        return null; // Return null if parsing fails
      }
    };
    getFromLocalStorage("branch");
  }, [selectedItem]);

  useEffect(() => {
    if (!selectedBranch?.id) {
      if (providerBranch?.branches) {
        localStorage.setItem(
          "branch",
          JSON.stringify(providerBranch?.branches[0])
        );
        setSelectedItem(providerBranch?.branches[0]);
      }
    }
  }, [selectedBranch, providerBranch]);

  return (
    <div className="sidebar--container">
      <div className={` ${"dashboard__sidenav"}`} role="navigation">
        <div className="dashboard__sidenav--banner">
          <img src={Logo} alt="fla" />
        </div>

        {location?.pathname !== "/onboarding" &&
          providerBranch?.branches?.length > 1 && (
            <div
              className="branch"
              onMouseOver={() => setOpenDropdown(true)}
              onMouseLeave={() => setOpenDropdown(false)}
            >
              <div className="branch__select">
                <div>
                  <div>
                    <p className="name">{selectedBranch.city}</p>
                    <p className="address">{selectedBranch.name}</p>
                  </div>
                </div>
                <Icon height={8} width={16} id={"downLine"} />
              </div>

              {openDropdown && (
                <div className="branch__dropdown">
                  <ul>
                    {providerBranch?.branches?.map((branch, index) => (
                      <li
                        onClick={() => {
                          setSelectedItem(branch);
                          localStorage.setItem(
                            "branch",
                            JSON.stringify(branch)
                          );
                        }}
                      >
                        <h4>Branch {index + 1}</h4>
                        <p>{branch?.city}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}

        {location?.pathname === "/onboarding" ? (
          <>
            <ul>
              <Link
                to={"onboarding"}
                // onClick={() => setActive(navigation.link)}
                className={`navlinks ${"active"} active`}
              >
                <Icon width="24px" height="24px" id={`onboarding`} />
                <p>{"ONBOARDING"}</p>
              </Link>
            </ul>
          </>
        ) : (
          <>
            {" "}
            <ul>
              {navigations.map((navigation) => {
                return (
                  <Link
                    to={navigation.link}
                    // onClick={() => setActive(navigation.link)}
                    className={`navlinks ${
                      splitLocation[1] === navigation.navName ? "active" : ""
                    } `}
                    key={navigation.id}
                  >
                    <Icon
                      width="25px"
                      height="25px"
                      id={`${
                        splitLocation[1] === navigation?.navName
                          ? navigation?.coloredIcon
                          : navigation?.icon
                      }`}
                    />
                    <p>{navigation?.name}</p>
                  </Link>
                );
              })}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardSIdebar;
