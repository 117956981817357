import React from "react";

const MobileTable = ({ key, children }) => {
  return (
    <div className="list-container" key={key}>
      {children}
    </div>
  );
};

export default MobileTable;
