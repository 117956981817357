import { format } from "date-fns";
import PagesLogo from "../assets/svgs/PagesIcon.svg";
import TechPr from "../assets/images/TechPr-logo.png";
import Zedi from "../assets/svgs/Zedi.svg";
import React from "react";
import { useLocation } from "react-router-dom";
export const carouselDetails = [
  {
    id: 0,
    name: "Onyinye Bassey",
    position: "Head of Strategic Sourcing and Procurement",
    testimonial: `“Finding a gym near work, booking classes, and tracking my progress - it's all in the app. Super convenient!”`,
    companyLogo: PagesLogo,
    altText: "Pages by dami",
  },
  {
    id: 1,
    name: "Alex Johnson",
    position: "Head of People",
    testimonial: `“I really like your service and would refer my friends. You guys seem like you really care, I would also like to know when I can add my family to a plan.”`,
    companyLogo: TechPr,
    altText: "TechPr logo",
  },
  {
    id: 2,
    name: "Gerry Franklin",
    position: "Founder and CEO",
    testimonial: `“I really like your service and I like how your follow-ups. I will definitely refer Flance to my circle. Keep up the good work.”`,
    companyLogo: Zedi,
    altText: "Zedi logo",
  },
];

export const paginate = (employees, itemsPerPage = 7) => {
  const numberOfPages = Math.ceil(employees.length / itemsPerPage);

  const newFollowers = Array.from({ length: numberOfPages }, (_, index) => {
    const start = index * itemsPerPage;
    return employees.slice(start, start + itemsPerPage);
  });

  return newFollowers;
};

export const dateFormat = (date, type) => {
  if (!date) return "--";
  return format(new Date(date), type);
};

export const fmtCurrency = (value, currency) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currencyDisplay: "symbol",
    currency: currency ? currency : "NGN",
  });
  return formatter.format(Number(value));
};

export const convertTo12HourFormat = (time) => {
  let [hours, minutes] = time.split(":").map(Number);
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format, replacing '00' with '12'
  return `${hours}:${minutes < 10 ? "0" + minutes : minutes} ${period}`;
};

export const limitString = (str, limit) => {
  return str?.length > limit ? str?.substring(0, limit) + "..." : str;
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function addFixedDuration(startDate, durationType) {
  const date = new Date(startDate);

  switch (durationType.toLowerCase()) {
    case "daily":
      date.setDate(date.getDate() + 1); // Add 1 day
      break;
    case "weekly":
      date.setDate(date.getDate() + 7); // Add 7 days (1 week)
      break;
    case "bi_weekly":
      date.setDate(date.getDate() + 14); // Add 14 days (1 week)
      break;
    case "monthly":
      date.setMonth(date.getMonth() + 1); // Add 1 month
      break;
    case "quarterly":
      date.setMonth(date.getMonth() + 3); // Add 1 month
      break;
    case "annually":
      date.setFullYear(date.getFullYear() + 1); // Add 1 year
      break;
    case "biannually":
      date.setMonth(date.getMonth() + 6); // Add 1 year
      break;
    default:
      return "MM/dd/yyyy";
  }

  return date;
}
