import React, { useEffect } from "react";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { Input } from "../../components/forms";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addContactPerson,
  getContactPerson,
} from "../../features/user/userSlice";
import * as Yup from "yup";

const KeyPerson = () => {
  const { submitClientsManuallyLoading, contactPerson } = useSelector(
    (store) => store.user
  );

  const schema = Yup.object({
    email: Yup.string().email("Invalid email address"),
  });
  const dispatch = useDispatch();
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(addContactPerson(values));
    },
  });

  useEffect(() => {
    dispatch(getContactPerson());
  }, []);

  useEffect(() => {
    setFieldValue("firstName", contactPerson?.firstName);
    setFieldValue("lastName", contactPerson?.lastName);
    setFieldValue("email", contactPerson?.emailAddress);
    setFieldValue("phone", contactPerson?.phoneNumber);
  }, [contactPerson]);

  return (
    <div className="keyperson">
      <div className="keyperson__header">
        <h3>Key Person</h3>
        <p>
          Your point of contact can be your HR, Payroll Officer, Admin etc. Note
          that an email will be sent to notify them.
        </p>
      </div>
      <div className="keyperson__body">
        <div className="keyperson__update">
          <Button
            variant="primary"
            type="submit"
            className="update"
            block
            size={"md"}
            onClick={handleSubmit}
          >
            {submitClientsManuallyLoading ? "Updating..." : "Update"}
          </Button>
        </div>
        <div className="keyperson__body--form mt-24">
          <div className="inputgroup">
            <Input
              type="text"
              id="firstName"
              label="FIrst Name"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              placeholder="First Name"
            />
            <Input
              type="text"
              id="lastName"
              label="Last Name"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              placeholder="First Name"
            />
          </div>
          <Input
            type="email"
            id="email"
            label="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="Email"
            onBlur={handleBlur}
            error={
              touched.email && errors.email ? (
                <small className="error">{errors.email}</small>
              ) : null
            }
          />
          <Input
            type="tel"
            id="phone"
            label="Phone Number"
            name="phone"
            value={values.phone}
            onChange={handleChange}
            placeholder="Phone Number"
            onBlur={handleBlur}
            error={
              touched.phone && errors.phone ? (
                <small className="error">{errors.phone}</small>
              ) : null
            }
          />
        </div>
      </div>
      <div className="keyperson__update--sm">
        <Button
          variant="primary"
          type="submit"
          className="update"
          block
          size={"md"}
          onClick={handleSubmit}
        >
          {submitClientsManuallyLoading ? "Updating..." : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default KeyPerson;
